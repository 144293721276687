










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AnimatedStarMap extends Vue
{
	mounted(): void
	{
		particlesJS.load("star-map", "/star-map.json");
		setTimeout(() => document.getElementById("star-map")!.classList.add("is-visible"), 2400);
	}
}

