





















import { Component, Vue } from 'vue-property-decorator';
import HomeSectionConditions from "@/components/HomeSectionConditions.vue";
import HomeSectionConstellations from "@/components/HomeSectionConstellations.vue";
import HomeSectionIntro from "../components/HomeSectionIntro.vue";
import HomeSectionLightPollution from "../components/HomeSectionLightPollution.vue";
import HomeSectionNightOfTheNight from "../components/HomeSectionNightOfTheNight.vue";
import HomeSectionStarterPack from "../components/HomeSectionStarterPack.vue";
import SideNav from "../components/SideNav.vue";

@Component({
	components: {
		HomeSectionConditions,
		HomeSectionConstellations,
		HomeSectionIntro,
		HomeSectionLightPollution,
		HomeSectionNightOfTheNight,
		HomeSectionStarterPack,
		SideNav
	}
})
export default class Home extends Vue
{
	isFullscreen: boolean = false;

	mounted(): void
	{
		this.isFullscreen = document.fullscreenElement !== null;

		document.addEventListener("fullscreenchange", () => this.isFullscreen = document.fullscreenElement !== null);
	}

	toggleFullscreen(): void
	{
		if (this.isFullscreen)
			document.exitFullscreen();
		else
			document.documentElement.requestFullscreen({navigationUI: "hide"});
	}
}

