















































import { Component, Vue } from 'vue-property-decorator';
import ScrollDownButton from "@/components/ScrollDownButton.vue";

interface Constellation
{
	file: string;
	name: string;
	description: string;
}

@Component({
	components: {ScrollDownButton}
})
export default class HomeSectionConstellations extends Vue
{
	constellations: Constellation[] = [
		{file: "air-pump", name: "Antlia - The Air Pump", description: "Antlia is a constellation created by the French astronomer Nicolas Louis de Lacaille in the 18th century. He originally named it Antlia Pneumatica in honour of French physicist Denis Papin's invention, the air pump."},
		{file: "aquarius", name: "Aquarius – The Water Bearer", description: "Aquarius is usually associated with Ganymede, the cup-carrier of the Olympic gods. Ganymede was the son of King Tros. He was the prettiest boy alive, and Zeus was so in love with him that he took the form of an eagle and abducted the boy. In some versions of the myth, Zeus sent an eagle, represented by the constellation Aquila, to fetch Ganymede and bring him to Olympus."},
		{file: "aquila", name: "Aquila - The Eagle", description: "Aquila constellation is sometimes associated with the eagle that held Zeus' thunderbolts in Greek mythology and other times with the eagle that abducted Ganymede and brought him to Olympus."},
		{file: "aries", name: "Aries - The Ram", description: "Aries is a winged ram with a Golden Fleece, sent by the nymph Nephele to save her son Phrixus after his father, King Athamas of Boeotia, received a false prediction that he had to sacrifice his son to ward off famine. Phrixus and his sister Helle climbed on the ram and were carried to Colchis on the Black Sea coast. Helle lost her grip and fell into the Dardanelles on the way. The Greeks called the channel separating Europe and Asia the Hellerospont in its memory. The Golden Fleece was later the object of the search of the Argonauts' for Colchis."},
		{file: "auriga", name: "Auriga - The Charioteer", description: "Auriga, the Charioteer, was said to have been placed in the sky by Zeus himself in honour of his inventor Erichthonius of Athens. Erichthonius was the son of the god Hephaestus, and he was raised by Athena, who taught him a lot. He was the first person to use four horses to a chariot and is credited as the inventor of the quadriga, the four-horse chariot. His chariot was named as the chariot of the Sun God."},
		{file: "big-dipper", name: "Ursa Major - The Big Bear", description: "Ursa Major, the \"great bear\" in Latin, is associated with many different myths in many cultures and represents a bear in many of the legends it is associated with across the globe. It is one of the most ancient constellations in the sky. In Greek mythology, it is most commonly taken to represent Callisto, a nymph Zeus fell in love with, whom his wife Hera turned into a bear. Sometimes the constellation is also associated with Adrasteia, a nymph who took care of Zeus when he was very young."},
		{file: "bootes", name: "Boötes - The Herdsman", description: "Boötes means \"the oxen-driver\" and is usually identified as the ploughman who drove the oxen represented by Ursa Major. The constellation is also sometimes associated with Arctophylax, or the Bear Keeper, also referring to Ursa Major, the Big Bear.\nIn another myth, Boötes represents Arcas, the son of Zeus and Callisto. In the myth, Callisto's father King Lycaon decides to test Zeus to see if the god is really who he says he is and serves him his son Arcas for dinner. Seeing what Lycaon has done, Zeus kills the king's sons and turns Lycaon into a wolf, then collects the parts of his own son and makes him whole again. Arcas' mother Callisto, in the meantime, gets turned into a bear, either by Zeus to disguise her and protect her from Hera's revenge or by the jealous Hera herself. When Arcas grows up, he comes face to face with his mother in the woods and does not recognize her. He starts to chase the bear and Zeus intervenes to prevent bloodshed and turns them into the constellations Ursa Major (Callisto) and Boötes (Arcas)."},
		{file: "camelopardalis", name: "Camelopardialis - The Giraffe", description: "Camelopardalis means \"the giraffe,\" and is a combination of the Greek words for the camel and the leopard. The Greeks came up with the name because the giraffe reminded them of the camel because of its long neck, and had spots like a leopard."},
		{file: "cancer", name: "Cancer - The Crab", description: "Cancer represents Karkinos, a crab sent by Hera to distract Heracles when he was fighting the Lemean Hydra as part of his Twelve Labours. Heracles kicked the crab so hard that it flew all the way into the sky, where it became a constellation. In a different version of the myth, Heracles crushed the crab underfoot, and Hera placed it into the sky."},
		{file: "canes-venatici", name: "Canes Venatici - The Hunting Dogs", description: "The constellation Canes Venatici was created by the Polish astronomer Johannes Hevelius in the 17th century. It represents two hunting dogs led by Boötes, the Herdsman, following the Great Bear (Ursa Major)."},
		{file: "canis-major", name: "Canis Major - The Big Dog", description: "Canis Minor is one of the Greek constellations. It represents the bigger dog following the mythical hunter Orion in pursuit of a hare, represented by Lepus constellation. The constellation is also sometimes associated with Laelaps, the fastest dog in the world, given to Europa by Zeus as a present."},
		{file: "canis-minor", name: "Canis Minor - The Small Dog", description: "Canis Minor represents the smaller dog following Orion."},
		{file: "capricornus", name: "Capricornus - The Sea Goat", description: "Capricornus means \"the goat.\" It's usually taken to represent the Greek forest deity Pan, who had the horns and legs of a goat. It is also sometimes identified as Amalthea, the goat that suckled the infant Zeus."},
		{file: "cassiopeia", name: "Cassiopeia - The Queen", description: "Cassiopeia represents the boastful queen herself, wife of Cepheus and mother of Andromeda. Cassiopeia claimed that she was more beautiful than the Nereids, and the nymphs asked the sea god Poseidon to punish the queen for her vanity. Poseidon sent the sea monster Cetus to ravage the queen's land. King Cepheus asked an oracle for help and was told that he had to sacrifice his daughter Andromeda to appease Poseidon. The king and queen left their daughter chained to a rock, but Andromeda was saved from the monster by Perseus. Cassiopeia and Cepheus were placed into the sky by Poseidon. It is said that Cassiopeia spends six months every year upside down in the sky as punishment for her boastfulness."},
		{file: "cepheus", name: "Cepheus - The King", description: "Cepheus represents King Cepheus of Aethiopia, husband of Cassiopeia and father of Andromeda."},
		{file: "cetus", name: "Cetus - The Whale", description: "Cetus represents the sea monster sent by Poseidon to ravage King Cepheus' land after his wife Cassiopeia had boasted that she was more beautiful than the Nereids and nymphs."},
		{file: "compass", name: "Pyxis - The Compass", description: "Pyxis constellation represents the mariner's compass. It was created by Nicolas Louis de Lacaille, who originally named it Pyxis Nautica."},
		{file: "corona-borealis", name: "Corona Borealis - The Northern Crown", description: "Corona Borealis represents the crown worn by the Cretan princess Ariadne at her wedding. After helping Theseus defeat the Minotaur and find his way out of the labyrinth, Ariadne sailed off with the hero but was abandoned by him on the island of Naxos. Dionysus saw her weeping there and fell in love. The two were later married. At the wedding, Ariadne wore a crown made by the god Hephaestus and threw it into the sky after the ceremony. It is said that the jewels turned into stars which now form the constellation Corona Borealis."},
		{file: "crater", name: "Crater - The Cup", description: "Crater represents the cup of Apollo, usually drawn as a two-handed chalice."},
		{file: "cygnus", name: "Cygnus - The Swan", description: "Cygnus is most commonly associated with the myth of Leda, the Spartan queen who gave birth to two sets of twins – the mortal Clytemnestra and Castor and the immortal Pollux and Helen – after being seduced by Zeus, who came to her in the form of a swan. The mortal children were fathered by Leda's husband Tyndareus and the immortal ones by Zeus. Castor and Pollux are associated with Gemini constellation."},
		{file: "delphinus", name: "Delphinus - The Dolphin", description: "Delphinus is associated with Poseidon's messenger, the dolphin that helped the god find the nymph Amphitrite, whom he later married. In a different myth, Delphinus is the dolphin that saved the life of the poet Arion. The god Apollo placed it into the sky next to Lyra constellation, which in this version of the myth represents Arion's lyre."},
		{file: "draco", name: "Draco - The Dragon", description: "Draco represents the dragon Ladon, the mythical creature with a hundred heads that guarded the gardens of the Hesperides. Heracles killed the dragon with his poisoned arrows as part of his Twelve Labours. In Roman legend, Draco is associated with one of the Giant Titans who warred with Zeus and other Olympian gods for a decade. The Titan was killed by Minerva in battle and thrown into the sky, where it froze around the North Pole."},
		{file: "equuleus", name: "Equuleus - The Liggle Horse", description: "Equuleus is usually associated with Hippe, the daughter of the centaur Chiron. Hippe was seduced by Aeolus and became pregnant with his child, but was too ashamed to tell her father about the pregnancy. She hid from Chiron in the mountains and, when he came looking for her, she prayed to the gods that he didn't find her. The gods granted her wish and turned her into a mare, represented by Equuleus constellation. Hippe is said to still be hiding from Chiron, with only her head showing behind Pegasus constellation."},
		{file: "eridanus", name: "Eridanus - The Celestial River", description: "Eridanus is the ancient Greek name for the river Po in Italy. Eridanus is usually associated with the story of Phaëton, the son of the Sun god Helios who begged his father to let him drive his chariot across the sky. Helios agreed to this and Phaëton mounted the chariot. However, since he was an inexperienced driver, he soon lost control of the horses, and the chariot plunged close to Earth, setting lands on fire and turning Libya into a desert. Zeus had to prevent further disaster, and he struck the young man down with a thunderbolt. Phaëton fell into the river Eridanus, and his father did not drive his chariot for days after his son's death, leaving the world in darkness."},
		{file: "gemini", name: "Gemini - The Twins", description: "Gemini constellation was named after the mythical twins Castor and Polydeuces. Two of the constellation's brightest stars carry the twins' names."},
		{file: "hercules", name: "Hercules - The Strong Man", description: "Hercules constellation was named after Heracles, the legendary hero from Greek mythology. It is one of the oldest constellations in the sky, dating back to Sumerian times."},
		{file: "hydra", name: "Hydra - The Water Serpent", description: "Hydra was named after the monster from the myth of Heracles' Twelve Labours. It is one of the Greek constellations, first documented by Ptolemy in the 2nd century."},
		{file: "hydrus", name: "Hydrus - The Water Snake", description: "Hydrus is one of the constellations introduced by Petrus Plancius in the late 16th century. It represents the sea snakes that Dutch explorers would have seen on their journeys to the East Indies."},
		{file: "leo", name: "Leo - The Lion", description: "Leo is associated with the Nemean lion in Greek mythology, the beast killed by Heracles as part of his Twelve Labours."},
		{file: "leo-minor", name: "Leo Minor - The Little Lion", description: "Leo Minor is one of the constellations introduced by Hevelius in the 17th century."},
		{file: "lepus", name: "Lepus - The Hare", description: "Lepus was first catalogued by Ptolemy in the 2nd century. It is often depicted as a hare chased by the hunter Orion and his two dogs, Canis Major and Canis Minor."},
		{file: "libra", name: "Libra - The Scales", description: "The name Libra means \"the weighing scales\", and the constellation represents the scales of justice held by Dike, the Greek goddess of justice, associated with Virgo constellation."},
		{file: "little-dipper", name: "Ursa Minor - The Small Bear", description: "Ursa Minor is usually associated with either Arcas, the son of Zeus and the nymph Callisto, or with Ida, one of the nymphs who took care of Zeus on Crete, where his mother Rhea had hidden him from his father because Cronus, fearful of an old prophecy that said he would be overthrown by one of his children, swallowed five of his older children after they were born."},
		{file: "lynx", name: "Lynx - The Lynx", description: "Lynx is introduced by Johannes Hevelius. Hevelius gave it this name because it is a faint constellation and it takes the eyesight of a lynx to see it."},
		{file: "lyra", name: "Lyra - The Harp", description: "Lyra constellation represents the lyre of Orpheus, the Greek poet and musician who died at the hands of the Bacchantes."},
		{file: "monoceros", name: "Monoveros - The Unicorn", description: "The name Monoceros means \"the unicorn\". The constellation was created by the Dutch astronomer, cartographer, and clergyman Petrus Plancius in 1612. He named it after the unicorn because the mythical animal appears several times in the Old Testament."},
		{file: "ophiuchus", name: "Ophiuchus - The Serpent Holder", description: "Ophiuchus represents Asclepius, the famous healer, and is usually depicted as a man holding a serpent, represented by the constellation Serpens. Asclepius is said to have learned how to bring people back from the dead when he saw one snake bringing healing herbs to another."},
		{file: "orion", name: "Orion - The Hunter", description: "Orion constellation represents the mythical hunter Orion, son of the sea god Poseidon and the Cretan princess Euryale. It is one of the oldest constellations known, also associated with the Sumerian myth of Gilgamesh."},
		{file: "oven", name: "Fornax - The Oven", description: "Fornax is one of the southern constellations created by Lacaille in the 18th century. It was initially named Fornax Chemica, after the chemical furnace, a small heater used in chemical experiments."},
		{file: "pegasus", name: "Pegasus - The Winged Horse", description: "Pegasus represents the white winged horse. The horse was said to have sprung from the neck of the Gorgon Medusa when Perseus beheaded her."},
		{file: "piscus", name: "Piscis - The Fishes", description: "Piscis is associated with a story about the goddess Aphrodite and her son Eros transforming into fish to escape the monster Typhon."},
		{file: "raven", name: "Corvus - The Raven", description: "Corvus represents the crow (or raven), the sacred bird of the god Apollo. In the myth, Apollo tells the raven to watch over his pregnant lover, Coronis. After a while, Coronis loses interest in the god and falls in love with a human man. When the bird reports the affair to Apollo, the god becomes so angry that the raven did not stop the affair that he curses it, scorching the raven's feathers and turning them black. (The bird originally had white feathers.) Coronis' son, the healer, Asclepius, is represented by Ophiuchus constellation."},
		{file: "sagitta", name: "Sagitta - The Arrow", description: "Sagitta represents the arrow that Heracles used to kill the eagle that gnawed on Prometheus' liver."},
		{file: "sagittarius", name: "Sagittarius - The Archer", description: "Sagittarius constellation represents a centaur aiming an arrow toward Antares, the bright star that marks the scorpion's heart. It is also frequently associated with Crotus, the son of Pan, who invented archery and lived on Mount Helicon. Crotus was said to have two feet and a satyr's tail. Sagittarius is also sometimes wrongly associated with the centaur Chiron, represented by Centaurus."},
		{file: "scorpio", name: "Scorpio - The Scorpion", description: "Scorpius represents the scorpion that killed the mythical hunter Orion. Orion is still said to be fleeing from the scorpion, and this is why the two constellations can never be seen in the sky at the same time: Orion sets just as Scorpius rises."},
		{file: "scutum", name: "Scutum - The Shield", description: "Scutum was created by Johannes Hevelius in the 17th century. He named the constellation Scutum Sobiescianum, or the Shield of Sobieski, to honour the victory of the Polish King Jan III Sobieski in the Battle of Vienna in 1683. The name was later shortened to Scutum, the shield."},
		{file: "serpens-caput", name: "Serpens - The Serpent", description: "Serpens is usually associated with the snake held by the healer Asclepius, represented by Ophiuchus."},
		{file: "southern-fish", name: "Piscis Austrinus - The Southern Fish", description: "Piscis Austrinus is one of the ancient constellations, dating back to Babylonian times. In Greek mythology, it represents the Great Fish and is usually depicted as swallowing the water poured by Aquarius."},
		{file: "stern", name: "Puppis - The Stern Of The Argo Navis", description: "Puppis represents the stern of the Argo Navis, the ship on which the Argonauts sailed to get the Golden Fleece. The ship used to be represented by a single larger constellation, which was split into three smaller ones in the 18th century."},
		{file: "taurus", name: "Taurus - The Bull", description: "Taurus represents the god Zeus, who in one tale transformed himself into a bull to seduce and abduct Europa, the beautiful daughter of the Phoenician King Agenor. In a different myth, the constellation is associated with the nymph Io, who was also seduced by Zeus. When the two were almost caught by Zeus' wife Hera, the god transformed Io into a heifer to protect her."},
		{file: "triangulum", name: "Triangulum - The Triangle", description: "Triangulum, or \"the triangle,\" catalogued by Ptolemy in the 2nd century. The Greeks called the constellation Deltoton because its shape resembled the Greek letter delta."},
		{file: "virgo", name: "Virgo - The Young Maiden", description: "Virgo is associated with Dike, the Greek goddess of justice, holding the scales of justice represented by the neighbouring constellation Libra."}
	];

	limit: number = 12;
	offset: number = 0;
	selected: number = -1;

	get constellationsFiltered(): any[]
	{
		return this.constellations.slice(this.offset, this.offset + this.limit);
	}

	get currentConstellation(): Constellation | undefined
	{
		if (this.selected >= 0 && this.selected < this.constellations.length)
			return this.constellations[this.selected];

		return undefined;
	}

	mounted(): void
	{
		this.constellations = this.constellations.map(constellation =>
		{
			constellation.description = constellation.description.replace(new RegExp("\n", "g"), "<br/>");

			return constellation;
		});
	}

	next(): void
	{
		this.offset += this.limit;
	}

	prev(): void
	{
		this.offset -= this.limit;
	}
}

