




































import { Component, Vue } from 'vue-property-decorator';
import ScrollDownButton from "@/components/ScrollDownButton.vue";

@Component({
	components: {ScrollDownButton}
})
export default class HomeSectionLightPollution extends Vue
{
}

