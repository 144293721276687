









import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ScrollDownButton extends Vue
{
	getScrollingElement(): HTMLElement
	{
		return <HTMLElement>document.querySelector("div#root-view.home")!;
	}

	getScrollTop(): number
	{
		return document.querySelector("div#root-view.home")!.scrollTop;
	}

	scrollDown(): void
	{
		let scrollTop = this.getScrollTop() - (this.getScrollTop() % window.innerHeight);

		document.querySelector("div#root-view.home")!.scrollTo({
			top: scrollTop + window.innerHeight,
			behavior: "smooth"
		})
	}
}

