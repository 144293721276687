











import { Component, Vue, Watch } from 'vue-property-decorator';

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number
{
	let l = array.length;

	while (l--)
		if (predicate(array[l], l, array))
			return l;

	return -1;
}

@Component
export default class SideNav extends Vue
{
	activeIndex: number = 0;
	isDark: boolean = false;
	isVisible: boolean = false;

	items: any[] = [
		{label: "Welcome", y: 0, dark: false},
		{label: "Is it the right time?", y: 1, dark: true},
		{label: "Constellations", y: 2, dark: true},
		{label: "Night of the Night", y: 3, dark: false},
		{label: "Light pollution", y: 4, dark: true},
		{label: "Get your starter pack", y: 5, dark: true}
	];

	destroyed(): void
	{
		this.getScrollingElement().removeEventListener("scroll", this.onWindowScroll);
	}

	mounted(): void
	{
		this.getScrollingElement().addEventListener("scroll", this.onWindowScroll);
		this.onWindowScroll();

		setTimeout(() => this.isVisible = true, 3000);

		const bulletPoints = <HTMLElement[]>Array.from(this.$el.querySelectorAll("a.nav-link span"));

		bulletPoints.forEach(p =>
		{
			requestAnimationFrame(() =>
			{
				p.style.setProperty("--max-width", `${p.getBoundingClientRect().width + 15}px`);

				requestAnimationFrame(() => p.classList.add("is-ready"));
			});
		});
	}

	getScrollingElement(): HTMLElement
	{
		return <HTMLElement>document.querySelector("div#root-view.home")!;
	}

	getScrollTop(): number
	{
		return document.querySelector("div#root-view.home")!.scrollTop;
	}

	onItemClick(item: any, index: number): void
	{
		if (this.activeIndex === index)
			return;

		this.getScrollingElement().scrollTo({
			top: item.y * window.innerHeight,
			behavior: "smooth"
		});
	}

	onWindowScroll(): void
	{
		const scrollTop = this.getScrollTop();
		const windowHeight = window.innerHeight;

		this.activeIndex = findLastIndex(this.items, item =>
		{
			const itemStart = item.y * windowHeight;
			const itemEnd = (item.y + 1) * windowHeight;
			const itemMiddle = itemEnd - (windowHeight / 2);

			if (scrollTop < itemStart && (scrollTop + windowHeight) < itemStart)
				return false;

			if ((scrollTop + windowHeight) >= itemMiddle || scrollTop >= itemStart)
				return true;

			return false;
		});
	}

	@Watch("activeIndex")
	onActiveIndexChanged(): void
	{
		this.isDark = this.items[this.activeIndex].dark;
	}
}

