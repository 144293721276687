























































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AnimatedLogo extends Vue
{
	mounted(): void
	{
		Array
			.from(this.$el.querySelectorAll("circle"))
			.sort(() => .5 - Math.random())
			.forEach((circle, index) => setTimeout(() => circle.classList.add("is-visible"), index * 60));

		Array
			.from(this.$el.querySelectorAll("path"))
			.forEach((circle, index) => setTimeout(() => circle.classList.add("is-visible"), index * 150 + 1500));
	}
}

