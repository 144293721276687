














import { Component, Vue } from 'vue-property-decorator';
import AnimatedLogo from "@/components/AnimatedLogo.vue";
import AnimatedStarMap from "@/components/AnimatedStarMap.vue";
import ScrollDownButton from "@/components/ScrollDownButton.vue";

@Component({
	components: {AnimatedStarMap, AnimatedLogo, ScrollDownButton}
})
export default class HomeSectionIntro extends Vue
{
	mounted(): void
	{
		setTimeout(() => this.$el.querySelector("h1")!.classList.add("is-visible"), 3000);
		setTimeout(() => this.$el.querySelector("h2")!.classList.add("is-visible"), 3400);
	}

	onButtonClicked(): void
	{
		window.scrollTo({top: window.innerHeight, behavior: "smooth"});
	}
}

