










































import { Component, Vue } from 'vue-property-decorator';
import { Latte } from "@bybas/latte-ui";

@Component
export default class HomeSectionStarterPack extends Vue
{
	async onApplyClick(): Promise<void>
	{
		await Latte.ui.snackbar.create({
			message: "If this was a real website, this would do something :-)"
		});
	}
}

